import { fetchSettings, updateSettings } from "@/modules/settings/workdays/services/settings.serivces"
import { MAP_WORK_DAYS } from "@/modules/settings/workdays/types"
import { notificationAction } from "@/utils"
import { NotificationEnum } from "@/utils/constants"
import { SaveOutlined } from "@ant-design/icons"
import { Button, Card, Checkbox, Flex, Row, Space, Typography } from "antd"
import React, { useEffect, useState } from "react"

const List: React.FC = () => {
  const [workDays, setWorkDays] = useState<number[]>([])

  useEffect(() => {
    fetchSettings("workdays").then(res => {
      setWorkDays(res.data.value)
    })
  }, [])

  const handleEditWorkDays = (value: number) => {
    let editWorkDays = [...workDays]
    if (editWorkDays.includes(value)) {
      editWorkDays = editWorkDays.filter(wd => wd !== value)
    } else {
      editWorkDays = [...editWorkDays, value]
    }
    setWorkDays(editWorkDays)
  }

  const handleSubmitWorkDay = async () => {
    try {
      const response = await updateSettings({ name: "workdays", value: JSON.stringify(workDays) })
      if (response.statusCode === 200) {
        notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
      }
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  return (
    <div>
      <Card size="small">
        <Flex justify="space-between">
          <Typography.Title level={4} style={styles.title}>
            Cài đặt lịch làm việc
          </Typography.Title>
        </Flex>

        <Card style={styles.card}>
          <Row style={styles.row}>
            {MAP_WORK_DAYS.map((workDay, index) => (
              <Checkbox
                key={index}
                onClick={() => handleEditWorkDays(workDay.value)}
                checked={workDays.includes(workDay.value)}
              >
                <Typography style={styles.text}>{workDay.name}</Typography>
              </Checkbox>
            ))}
          </Row>
        </Card>
        <Space style={styles.button}>
          <Button type="primary" onClick={handleSubmitWorkDay} icon={<SaveOutlined />}>
            Lưu
          </Button>
        </Space>
      </Card>
    </div>
  )
}
export default List
const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  text: {
    fontSize: "18px",
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
  tag: {
    display: "flex",
    width: "120px",
    justifyContent: "space-between",
    flexDirection: "column",
    textAlign: "center",
  },
  row: { display: "flex", justifyContent: "space-between" },
  button: { display: "flex", justifyContent: "flex-end" },
}
