import Filters, { FilterComponent } from "@/components/Filters"
import { deleteCheckIn, fetchCheckIn, updateCheckIn } from "@/modules/hrs/check_ins/services/checkIn.services"
import { CheckInData } from "@/modules/hrs/check_ins/types"
import CheckinForm from "@/modules/hrs/check_ins/views/CheckIn"
import ModalCreateMass from "@/modules/hrs/check_ins/views/ModalCreateMass"
import { Staff } from "@/modules/hrs/staffs"
import { fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { ResponseData } from "@/types"
import { normalizeText, notificationAction } from "@/utils"
import { NotificationEnum } from "@/utils/constants"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const CheckInList: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<CheckInData>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [staffs, setStaffs] = useState<{ value: string | number; label: string }[]>([])
  const [openModalCreateMass, setOpenModalCreateMass] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)

  const onRefresh = () => {
    setNeedRefresh(!needRefresh)
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }

  const onDismissModal = () => {
    setOpen(false)
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const showModal = () => {
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/hrs/checkin/${id}${location.search}`)
    setOpen(true)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "staff",
      key: "staff",
      width: "30%",
      render: staff => staff.name,
    },
    {
      title: "Tháng",
      dataIndex: "month",
      key: "month",
      width: "10%",
      render: (month: Date) => dayjs(month).format("MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "settlement",
      key: "settlement",
      width: "25%",
      render: settlement => {
        return (
          <Tag color={settlement ? "success" : "error"}>
            {settlement ? "Đã xác nhận & khoá chấm công" : "Đang mở chấm công"}
          </Tag>
        )
      },
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Space>
            <Button
              ghost
              type="primary"
              shape="round"
              size="small"
              onClick={() => showModalEdit(record?.id)}
              style={{ borderColor: "#B7EB8F", color: "#57C521" }}
            >
              {!record?.settlement ? "Sửa" : "Xem"}
            </Button>
            {!record?.settlement && (
              <Popconfirm
                title="Xác nhận chấm công và khoá bảng chấm công cho tháng này?"
                onConfirm={() => onSettleCheckIns(record as CheckInData)}
              >
                <Button ghost type="primary" shape="round" size="small">
                  Xác nhận
                </Button>
              </Popconfirm>
            )}
            <Popconfirm title="Bạn có chắc chắn muốn xóa?" onConfirm={() => handleDeleteCheckIn(record as CheckInData)}>
              <Button type="default" shape="round" danger size="small">
                Xóa
              </Button>
            </Popconfirm>
          </Space>
        ) : null,
      width: "25%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: CheckInData) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  useEffect(() => {
    fetchStaffs({ limit: 1000 }).then((res: any) => {
      if (res.data.items && res.data.items.length) {
        const mappedStaffs = res.data.items.map((staff: Staff) => ({ value: staff.id, label: staff.name }))
        setStaffs(mappedStaffs)
      }
    })

    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "month") {
          filterValues[key] = dayjs(value)
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      if (values.month) {
        newValue = { ...newValue, month: dayjs(new Date(values.month)).format("YYYY-MM") }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchCheckIn(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  const onSettleCheckIns = async (checkIn: CheckInData) => {
    if (checkIn.id) {
      try {
        await updateCheckIn(checkIn.id, { ...checkIn, settlement: true })
        notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
        setNeedRefresh(!needRefresh)
      } catch (error) {
        console.error(error)
        notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
      }
    }
  }

  const handleDeleteCheckIn = async (record: CheckInData) => {
    await deleteCheckIn({ ...record })
    setNeedRefresh(!needRefresh)
  }

  return (
    <div>
      <Card size="small">
        <Flex justify="space-between" style={{ flexWrap: "wrap" }}>
          <Typography.Title level={4} style={styles.title}>
            Danh sách bảng chấm công
          </Typography.Title>
          <Space style={{ flexWrap: "wrap", marginTop: "10px" }}>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
            <Button type="primary" onClick={() => setOpenModalCreateMass(true)} icon={<PlusOutlined />}>
              Thêm mới cho tất cả nhân viên
            </Button>
          </Space>
        </Flex>

        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Nhân viên",
                  placeholder: "Chọn nhân viên",
                  name: "staff_id",
                  type: "select-search",
                  options: staffs,
                },
                {
                  label: "Tháng",
                  placeholder: "Chọn tháng",
                  name: "month",
                  type: "date",
                  picker: "month",
                },
                {
                  label: "Trạng thái",
                  placeholder: "Vui lòng chọn trạng thái",
                  name: "settlement",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: true, label: "Đã chấm công" },
                    { value: false, label: "Chưa chấm công" },
                  ],
                  defaultValue: "",
                },
              ] as FilterComponent[]
            }
          />
        </Card>
        {open && <CheckinForm openProps={open} cancelProps={onDismissModal} isNew />}
        {openModalCreateMass && (
          <ModalCreateMass
            openProps={openModalCreateMass}
            cancelProps={() => setOpenModalCreateMass(false)}
            refreshProps={onRefresh}
          />
        )}
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ x: 1200, y: 400 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
            responsive: true,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default CheckInList

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
