import { ACCESS_TOKEN } from "@/constants"
import { User } from "@/modules/manages/users"
import { fetchUsers } from "@/modules/manages/users/services/users.services"
import { fetchInventories } from "@/modules/warehouses/inventories/services/inventories.services"
import { InventoryType } from "@/modules/warehouses/inventories/types"
import {
  createInventoryTransaction,
  fetchInventoryTransaction,
  updateInventoryTransaction,
} from "@/modules/warehouses/inventory-transactions/services/inventoryTransactions.services"
import {
  InventoryTransactionType,
  Mapped_transactionType,
  TransactionEnum,
} from "@/modules/warehouses/inventory-transactions/types"
import { dateFormatList, normalizeText, notificationAction, parseJwt } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { useLocalStorageState } from "ahooks"
import { Button, DatePicker, Form, Input, Modal, Select, Space } from "antd"
import TextArea from "antd/es/input/TextArea"
import { InputNumber } from "antd/lib"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
  inventoryInit?: InventoryType
  typeInit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [formInventory] = Form.useForm()
  const [isOpenModelAdd, setIsOpenModelAdd] = useState<boolean>(false)
  const [dataInventories, setDataInventories] = useState<InventoryType[]>()
  const [inventoryData, setInventoryData] = useState<InventoryTransactionType | object>({})
  const [dataUsers, setDataUsers] = useState<User[]>()
  const [dataToken, setDataToken] = useState<{ fullName: string; id: string }>({ fullName: "", id: "" })
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const getUsers = async () => {
    try {
      const response = await fetchUsers()
      if (response?.data) {
        setDataUsers(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const getInventories = async () => {
    try {
      const response = await fetchInventories()
      if (response?.data) {
        setDataInventories(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }
  const [accessToken, setAccessToken] = useLocalStorageState<string | null>(ACCESS_TOKEN)
  useEffect(() => {
    decodeToken();
  }, [accessToken])
  const decodeToken = () => {
    const decodedToken = accessToken && parseJwt(accessToken)
    if (decodedToken) {
      setDataToken({
        fullName: decodedToken.name || "",
        id: decodedToken.sub || "",
      })
    }
  }
  useEffect(() => {
    // Cập nhật form khi dataToken thay đổi
    if (dataToken?.id) {
      formInventory.setFieldsValue({
        user_id: dataToken.id,
        fullName: dataToken.fullName,
      })
    }
  }, [dataToken, formInventory])
  const onReset = () => {
    formInventory.resetFields()
  }

  useEffect(() => {
    getInventories()
    getUsers()
  }, [loadingData])

  const handleDismissModal = () => {
    if (isOpenModelAdd) {
      setIsOpenModelAdd(false)
    } else {
      props.cancelProps()
      onReset()
    }
  }


  const handleCompleteForm = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      onReset()
      props.refreshProps()
      handleDismissModal()
    }, 1000)
  }

  const handleAdd = async () => {
    try {
      const values = await formInventory.validateFields()
      if (values.quantity) {
        const inventory = dataInventories?.find(s => s.id === values.inventory_id)
        if (values.transaction_type === TransactionEnum.OUT && !inventory?.quantity >= values.quantity) {
          notificationAction("Vui lòng chọn số lượng hàng hóa ít hơn hoặc bằng tồn kho", NotificationEnum.ERROR)
          return
        }
        const newData: InventoryTransactionType = {
          status: StatusEnum.ACTIVE,
          user_id: dataToken.id,
          ...values,
        }
        const response = await createInventoryTransaction(newData)
        if (response.statusCode === 200) {
          handleCompleteForm()
          setLoadingData(!loadingData)
          notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
        }
      } else notificationAction("Vui lòng nhập thông tin đúng vào form! ", NotificationEnum.ERROR)
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      formInventory.resetFields()
    }
    if (props.inventoryInit) {
      formInventory.setFieldsValue({
        inventory_id: props.inventoryInit.id,
        inventory_quantity: props.inventoryInit.quantity,
        unit_id: props.inventoryInit.unit.name,
        user_id: dataToken?.id,
        transaction_type: props.typeInit,
      });
    }

    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchInventoryTransaction(props.idEdit).then(res => {
        formInventory.setFieldsValue({
          inventory_id: res.data.inventory_id,
          quantity: res.data.quantity,
          transaction_type: res.data.transaction_type,
          notes: res.data.notes,
          user_id: res.data.user_id,
          transaction_date: dayjs(res.data.transaction_date),
        })
        setInventoryData(res.data)
      })
    }
  }, [props.idEdit, props.actionType, props.inventoryInit])

  const handleEdit = async () => {
    try {
      if (props.idEdit) {
        const values = await formInventory.validateFields()
        if (values.quantity) {
          const response = await updateInventoryTransaction(props.idEdit, {
            ...inventoryData,
            ...values,
          })
          if (response.statusCode === 200) {
            handleCompleteForm()
            setLoadingData(!loadingData)
            notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
          }
        }
      }
      notificationAction("Vui lòng nhập đúng thông tin! ", NotificationEnum.ERROR)
    } catch (error) {
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button key="cancel" onClick={handleDismissModal}>
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleEdit} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAdd()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    const key = Object.keys(changedValues)[0]
    const value = Object.values(changedValues)[0]

    if (key === "inventory_id") {
      const inventory = dataInventories?.find(s => s.id === value)
      if (!inventory) {
        formInventory.setFieldValue("unit_id", "")
        return
      }

      formInventory.setFieldValue("unit_id", inventory.unit.name)
      formInventory.setFieldValue("inventory_quantity", inventory.quantity)
    }
  }
  return (
    <>
      <Modal
        title={props.actionType === "CREATE" ? "Thêm kiểm kê kho" : "Quản lí xuất nhập kho"}
        open={!isOpenModelAdd && props.openProps}
        width={600}
        onCancel={props.cancelProps}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "140px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          form={formInventory}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            label="Loại giao dịch"
            name="transaction_type"
            rules={[{ required: true, message: "Vui lòng chọn đơn vị!" }]}
          >
            <Select
              placeholder="Vui lòng chọn loại giao dịch"
              style={{ width: "100%" }}
              options={[
                { value: TransactionEnum.IN, label: Mapped_transactionType[TransactionEnum.IN] },
                { value: TransactionEnum.OUT, label: Mapped_transactionType[TransactionEnum.OUT] },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Tên hàng hoá"
            name="inventory_id"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Select
              showSearch
              placeholder="Chọn loại hàng hoá"
              optionFilterProp="children"
              filterOption={filterOption}
              options={dataInventories?.map(item => ({
                value: item.id as string,
                label: item.name,
              }))}
            />
          </Form.Item>
          {props.actionType === "CREATE" && (
            <>
              <Form.Item label="Số lượng trong kho:" name="inventory_quantity">
                <InputNumber placeholder="Số lượng" step={1} min={0} style={{ width: "100%" }} disabled readOnly />
              </Form.Item>

              <Form.Item label="Đơn vị:" name="unit_id">
                <Input placeholder="Đơn vị" disabled readOnly />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Số lượng:"
            name="quantity"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <InputNumber placeholder="Nhập số lượng" step={1} min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="Ghi chú:" name="notes">
            <TextArea placeholder="Ghi chú" />
          </Form.Item>
          <Form.Item label="Người tạo">
            <Input value={dataToken?.fullName} disabled />
          </Form.Item>
          <Form.Item name="user_id" hidden initialValue={dataToken.id}>
            <Input value={dataToken?.id} />
          </Form.Item>
          <Form.Item
            label="Ngày diễn ra"
            name="transaction_date"
            initialValue={dayjs(new Date())}
            rules={[{ required: true, message: "Vui lòng chọn ngày diễn ra!" }]}
          >
            <DatePicker placeholder="Chọn ngày" format={dateFormatList} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UpsertForm

const styles = {
  inputNumber: {
    width: "410px",
  },
}
