import Filters, { FilterComponent } from "@/components/Filters"
import { InternalDebt } from "@/modules/hrs/internal_debts"
import { createInternalDebt, updateInternalDebt } from "@/modules/hrs/internal_debts/services/internaldebts.services"
import { deletePaySheet, fetchPaySheets, updatePaySheet } from "@/modules/hrs/paysheets/services/paysheet.services"
import { DataPaysheet, Paysheet } from "@/modules/hrs/paysheets/types"
import UpsertForm from "@/modules/hrs/paysheets/views/UpsertForm"
import { Staff } from "@/modules/hrs/staffs"
import { fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { formatMoney, normalizeText, notificationAction } from "@/utils/helpers"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const App: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataPaysheet>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [currentPage, setCurrentPage] = useState(1)
  const [staffs, setStaffs] = useState<{ value: string | number; label: string }[]>([])
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const navigate = useNavigate()

  const onRefresh = () => {
    setNeedRefresh(!needRefresh)
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }

  const onDismissModal = () => {
    setOpen(false)
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/hrs/paysheets/${id}${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 60,
      fixed: isMobile ? undefined : "left",
    },
    {
      title: "Bảng lương",
      dataIndex: "name",
      key: "name",
      width: 120,
      fixed: isMobile ? undefined : "left",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "staff",
      key: "staff",
      width: 120,
      fixed: isMobile ? undefined : "left",
      render: staff => staff.name,
    },
    {
      title: "Số ngày công",
      dataIndex: "total_work_days",
      key: "total_work_days",
      width: 120,
    },
    {
      title: "Lương cơ bản",
      dataIndex: "basic_income",
      key: "basic_income",
      width: 160,
      render: basic_income => `${formatMoney(basic_income)} đ`,
    },
    {
      title: "Thưởng",
      dataIndex: "extra_income",
      key: "extra_income",
      width: 160,
      render: extra_income => `${formatMoney(extra_income)} đ`,
    },
    {
      title: "Lương ứng",
      dataIndex: "advance_payment",
      key: "advance_payment",
      width: 160,
      render: advance_payment => `${formatMoney(advance_payment)} đ`,
    },
    {
      title: "Tiền phạt",
      dataIndex: "negative_income",
      key: "negative_income",
      width: 160,
      render: negative_income => `${formatMoney(negative_income ?? 0)} đ`,
    },
    {
      title: "Lương theo ngày công",
      dataIndex: "income_by_day",
      key: "income_by_day",
      width: 160,
      render: income_by_day => `${formatMoney(income_by_day)} đ`,
    },
    {
      title: "Tổng lương thực tế",
      dataIndex: "total_income",
      key: "total_income",
      width: 160,
      render: total_income => `${formatMoney(total_income)} đ`,
    },
    {
      title: "Tổng lương thực nhận",
      dataIndex: "final_income",
      key: "final_income",
      width: 160,
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      sortOrder: sortPaginationValues
        ? sortPaginationValues.final_income
          ? sortPaginationValues.final_income === "asc"
            ? "ascend"
            : "descend"
          : null
        : null,
      render: final_income => `${formatMoney(final_income)} đ`,
    },
    {
      title: "Ngày trả lương",
      dataIndex: "payday",
      key: "payday",
      width: 160,
      render: payday => payday && dayjs(new Date(payday)).format("DD/MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "has_settle",
      key: "has_settle",
      width: 160,
      fixed: isMobile ? undefined : "right",
      render: has_settle => {
        return <Tag color={has_settle ? "success" : "error"}>{has_settle ? "Đã thanh toán" : "Chưa thanh toán"}</Tag>
      },
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      width: 260,
      fixed: isMobile ? undefined : "right",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Flex wrap="wrap" gap="middle">
            <Space>
              <Button ghost type="primary" shape="round" size="small" onClick={() => showModalEdit(record?.id)}>
                Sửa
              </Button>
              {!record.has_settle && (
                <Popconfirm
                  title="Bạn có chắc chắn muốn xóa?"
                  onConfirm={() => handleDeletePaySheet(record as Paysheet)}
                >
                  <Button type="default" shape="round" danger size="small">
                    Xóa
                  </Button>
                </Popconfirm>
              )}
            </Space>
            <Space>
              {!record.has_settle && (
                <Popconfirm title="Thanh toán bảng lương này?" onConfirm={() => onSettlePaySheets(record as Paysheet)}>
                  <Button ghost type="primary" shape="round" size="small">
                    Thanh toán
                  </Button>
                </Popconfirm>
              )}
              <Button
                ghost
                type="primary"
                shape="round"
                size="small"
                onClick={() => navigate(`/hrs/paysheets/paycheck_bill/${record?.id}`)}
              >
                In phiếu lương
              </Button>
            </Space>
          </Flex>
        ) : null,
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataPaysheet) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  useEffect(() => {
    fetchStaffs({ limit: 1000 }).then((res: any) => {
      if (res.data.items && res.data.items.length) {
        const mappedStaffs = res.data.items.map((staff: Staff) => ({ value: staff.id, label: staff.name }))
        setStaffs(mappedStaffs)
      }
    })

    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "selected_month") {
          filterValues[key] = dayjs(value)
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchPaySheets(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      if (values.selected_month) {
        newValue = { ...newValue, selected_month: dayjs(new Date(values.selected_month)).format("YYYY-MM") }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  const onSettlePaySheets = (paySheet: Paysheet) => {
    if (paySheet.id) {
      if (paySheet.staff && paySheet.staff.internal_debts && paySheet.staff.internal_debts.length > 0) {
        for (let i = 0; i < paySheet.staff.internal_debts.length; i++) {
          const debt = paySheet.staff.internal_debts[i]
          if (debt && debt.id && !debt.settlement) {
            updateInternalDebt(debt.id, { ...debt, settlement: true })
          }
        }
      }
      if (parseInt(paySheet.final_income) < 0) {
        const newDate: InternalDebt = {
          borrower_id: paySheet.staff_id,
          amount: Math.abs(parseInt(paySheet.final_income)),
          settlement: false,
          date_happened: dayjs(new Date()).toISOString(),
          status: StatusEnum.ACTIVE,
          note: "Tiền ứng này được tính từ tiền âm từ bảng lương",
        }
        createInternalDebt(newDate)
        updatePaySheet(paySheet.id, { ...paySheet, has_settle: true, final_income: "0" }).then(res => {
          setNeedRefresh(!needRefresh)
        })
        notificationAction(NotificationEnum.SETTLING, NotificationEnum.SUCCESS)
      } else {
        updatePaySheet(paySheet.id, { ...paySheet, has_settle: true }).then(res => setNeedRefresh(!needRefresh))
        notificationAction(NotificationEnum.SETTLING, NotificationEnum.SUCCESS)
      }
    }
  }

  const handleDeletePaySheet = async (record: Paysheet) => {
    await deletePaySheet(record)
    setNeedRefresh(!needRefresh)
  }

  return (
    <div>
      <Card size="small">
        <Flex justify="space-between" style={{ flexWrap: "wrap" }}>
          <Typography.Title level={4} style={styles.title}>
            Danh sách bảng lương
          </Typography.Title>
          <Space style={{ flexWrap: "wrap", marginTop: "10px" }}>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Space>
        </Flex>

        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Nhân viên",
                  placeholder: "Chọn nhân viên",
                  name: "staff_id",
                  type: "select-search",
                  options: staffs,
                },
                {
                  label: "Trạng thái",
                  placeholder: "Vui lòng chọn trạng thái",
                  name: "has_settle",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: true, label: "Đã thanh toán" },
                    { value: false, label: "Chưa thanh toán" },
                  ],
                  defaultValue: "",
                },
                {
                  label: "Tháng",
                  placeholder: "Chọn tháng",
                  name: "selected_month",
                  type: "date",
                  picker: "month",
                },
              ] as FilterComponent[]
            }
          />
        </Card>
        <UpsertForm
          openProps={open}
          cancelProps={onDismissModal}
          refreshProps={onRefresh}
          actionType={actionType}
          idEdit={idEdit}
        />

        {dataSource && dataSource.incomeAndAdvancePayment && (
          <>
            <Flex align="center" justify="flex-start" gap={16} wrap="wrap">
              {dataSource.incomeAndAdvancePayment.basic_income ? (
                <Typography.Text style={{ fontSize: "1.2rem" }}>
                  <Typography.Text>Tổng lương cơ bản: </Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(dataSource.incomeAndAdvancePayment.basic_income)} đ
                  </Typography.Text>
                </Typography.Text>
              ) : null}
              {dataSource.incomeAndAdvancePayment.income_by_day ? (
                <Typography.Text style={{ fontSize: "1.2rem" }}>
                  <Typography.Text>Tổng lương theo ngày công: </Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(dataSource.incomeAndAdvancePayment.income_by_day)} đ
                  </Typography.Text>
                </Typography.Text>
              ) : null}
              {dataSource.incomeAndAdvancePayment.advance_payment && (
                <Typography.Text style={{ fontSize: "1.2rem" }}>
                  <Typography.Text>Tổng lương ứng: </Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(dataSource.incomeAndAdvancePayment.advance_payment)} đ
                  </Typography.Text>
                </Typography.Text>
              )}
              {dataSource.incomeAndAdvancePayment.total_income ? (
                <Typography.Text style={{ fontSize: "1.2rem" }}>
                  <Typography.Text>Tổng lương thực tế: </Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(dataSource.incomeAndAdvancePayment.total_income)} đ
                  </Typography.Text>
                </Typography.Text>
              ) : null}
              {dataSource.incomeAndAdvancePayment.final_income ? (
                <Typography.Text style={{ fontSize: "1.2rem" }}>
                  <Typography.Text>Tổng lương thực nhận: </Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(dataSource.incomeAndAdvancePayment.final_income)} đ
                  </Typography.Text>
                </Typography.Text>
              ) : null}
            </Flex>
            <Flex style={{ marginBottom: "1.25rem" }}>
              {dataSource.incomeAndAdvancePayment.total_count ? (
                <Typography.Text style={{ fontSize: "1rem" }}>
                  <Typography.Text>Có </Typography.Text>
                  <Typography.Text strong>{dataSource.incomeAndAdvancePayment.total_count}</Typography.Text>
                  <Typography.Text> bảng lương được tìm thấy</Typography.Text>
                </Typography.Text>
              ) : null}
            </Flex>
          </>
        )}

        <Table
          rowClassName={() => "editable-row"}
          bordered
          virtual={isMobile ? false : true}
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ x: 2000, y: 1000 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
            responsive: true,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default App

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
