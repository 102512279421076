import { Upsert } from "@/modules/warehouses/inventory-categories/view/Upsert"
import { Route, Routes } from "react-router-dom"
import List from "./view/List"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path=":id" element={<Upsert />} />
    </Routes>
  )
}
