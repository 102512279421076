// If the isInstantSearch prop is true, the search will be triggered on every filter change. If it's false, the search will be triggered when the user clicks the search button.

import { filterOption } from "@/utils"
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Slider, Space, Switch } from "antd"
import { isBoolean, isEmpty } from "lodash"
import moment from "moment"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"

export type RadioOption = {
  value: string | number
  label: string
  defaultChecked?: boolean
  disabled?: boolean
}
export const { RangePicker } = DatePicker

export interface FilterComponent {
  type:
    | "text"
    | "select"
    | "date"
    | "number"
    | "range"
    | "checkbox"
    | "radio"
    | "switch"
    | "select-multiple"
    | "live-search"
    | "range_picker"
    | "debounce-search"
    | "select-search"
  name: string
  label: string
  options?: string[] | number[] | object[] | RadioOption[]
  placeholder?: string
  defaultValue?: any
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  typeInputNumber?: boolean
  onChange?: (value: any) => void
  onBlur?: (value: any) => void
  picker?: "time" | "date" | "month" | "week" | "quarter" | "year"
}

export interface FiltersProps {
  filters: FilterComponent[]
  onSubmit: (filters: object) => void
  isInstantSearch?: boolean
  isClearFilter?: boolean
  onSearch?: (filters: object) => void
  filterRef: React.Ref<{ filterFunction: () => void }>
  defaultFilterValues: { [key: string]: string } | null
}

const Filters: React.FC<FiltersProps> = ({
  filters,
  onSubmit,
  isInstantSearch = true,
  isClearFilter = false,
  onSearch,
  filterRef,
  defaultFilterValues,
}) => {
  const [defaultValues, setDefaultValues] = useState<object>({})
  const [filterValues, setFilterValues] = useState<object | null>(null)
  const [searchValues, setSearchValues] = useState<object>({})
  const [form] = Form.useForm()
  const clearFilterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  const [showFilters, setShowFilters] = useState<boolean>(false)

  const handleClickShowFilters = () => {
    setShowFilters(prev => !prev)
  }

  const handleInputChange = (name: string, value: any) => {
    setFilterValues(prevValues => {
      if (!isBoolean(value) && !value) {
        // @ts-ignore
        delete prevValues[name]
        return { ...prevValues }
      }

      return {
        ...prevValues,
        [name]: value,
      }
    })
  }

  const handleInputSearch = (name: string, value: any) => {
    setSearchValues(prevValues => ({ ...prevValues, [name]: value }))
  }

  const handleClearFilters = (values: object) => {
    setSearchValues({})
    setFilterValues({ ...defaultValues })
    form.resetFields()
    form.setFieldsValue({ ...defaultValues })
  }

  useEffect(() => {
    clearFilterRef.current = { filterFunction: () => handleClearFilters(defaultValues) }
  }, [defaultValues])

  useImperativeHandle(filterRef, () => ({
    filterFunction: () => {
      if (clearFilterRef.current && clearFilterRef.current.filterFunction) {
        clearFilterRef.current.filterFunction()
      }
    },
  }))

  const handleSubmitFilters = () => {
    if (filterValues) {
      onSubmit(filterValues)
    }
  }

  const renderFilterComponent = (filter: FilterComponent) => {
    let textDebounceSearch: any
    let numberDebounceSearch: any
    switch (filter.type) {
      case "text":
        return (
          <Input
            key={filter.name}
            placeholder={filter.placeholder}
            onChange={e => {
              clearTimeout(textDebounceSearch)
              textDebounceSearch = setTimeout(() => {
                handleInputChange(filter.name, e.target.value)
              }, 400)
            }}
          />
        )
      case "select":
        return (
          <Select
            key={filter.name}
            onChange={value => handleInputChange(filter.name, value)}
            style={{ minWidth: "9rem" }}
          >
            {(filter.options as RadioOption[]).map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      case "date":
        return (
          <DatePicker
            key={filter.name}
            onChange={(date, dateString) => handleInputChange(filter.name, date ? date.toDate() : null)}
            picker={filter.picker}
            style={{ width: "18rem" }}
            format={filter.picker === "month" ? "MM/YYYY" : "DD/MM/YYYY"}
          />
        )

      case "number":
        return (
          <InputNumber
            key={filter.name}
            onChange={value => {
              clearTimeout(numberDebounceSearch)
              numberDebounceSearch = setTimeout(() => {
                handleInputChange(filter.name, value)
              }, 400)
            }}
            formatter={(value: any) =>
              filter.typeInputNumber ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value
            }
            step={10000}
            min={0}
          />
        )
      case "range":
        return <Slider key={filter.name} range onChange={value => handleInputChange(filter.name, value)} />
      case "checkbox":
        return (
          <Checkbox.Group
            key={filter.name}
            options={(filter.options as RadioOption[])?.map((option: { value: string | number; label: string }) => ({
              label: option.label,
              value: option.value,
            }))}
            onChange={values => handleInputChange(filter.name, values)}
          />
        )
      case "radio":
        return (
          <Radio.Group
            key={filter.name}
            options={(filter.options as RadioOption[])?.map((option: { value: string | number; label: string }) => ({
              label: option.label,
              value: option.value,
            }))}
            onChange={e => handleInputChange(filter.name, e.target.value)}
          />
        )
      case "switch":
        return (
          <Switch
            key={filter.name}
            defaultChecked={filter.defaultValue}
            onChange={checked => handleInputChange(filter.name, checked)}
          />
        )
      case "select-multiple":
        return (
          <Select key={filter.name} mode="multiple" onChange={values => handleInputChange(filter.name, values)}>
            {(filter.options as string[])?.map((option: string | number) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        )
      case "live-search":
        return (
          <Select
            key={filter.name}
            showSearch
            onSearch={value => handleInputSearch(filter.name, value)}
            onChange={value => handleInputChange(filter.name, value)}
            filterOption={false}
            options={(filter.options as RadioOption[])?.map((option: { value: string | number; label: string }) => ({
              label: option.label,
              value: option.value,
            }))}
            style={{ minWidth: "14rem" }}
            allowClear
            onClear={() => {
              handleInputSearch(filter.name, "")
              handleInputChange(filter.name, "")
            }}
          />
        )
      case "select-search":
        return (
          <Select
            key={filter.name}
            showSearch
            onSearch={value => handleInputSearch(filter.name, value)}
            onChange={value => handleInputChange(filter.name, value)}
            style={{ minWidth: "14rem" }}
            placeholder={filter.placeholder}
            optionFilterProp="children"
            filterOption={filterOption}
            options={(filter.options as RadioOption[])?.map((option: { value: string | number; label: string }) => ({
              label: option.label,
              value: option.value.toString(),
            }))}
            allowClear
            onClear={() => {
              handleInputSearch(filter.name, "")
              handleInputChange(filter.name, "")
            }}
          />
        )
      default:
        return null
    }
  }

  useEffect(() => {
    let defaultFilter: { [key: string]: any } = {}
    filters.forEach(filter => {
      if (!isBoolean(filter.defaultValue) && !filter.defaultValue) {
        return
      }
      defaultFilter = { ...defaultFilter, [filter.name]: filter.defaultValue }
    })
    if (!isEmpty(defaultFilter)) {
      setDefaultValues({ ...defaultFilter })
      // setFilterValues({ ...defaultFilter })
    }
  }, [])

  useEffect(() => {
    if (isInstantSearch && filterValues) {
      onSubmit(filterValues)
    }
  }, [filterValues, isInstantSearch])

  useEffect(() => {
    onSearch && onSearch(searchValues)
  }, [searchValues])

  useEffect(() => {
    if (defaultFilterValues) {
      setFilterValues({ ...defaultFilterValues })
      form.setFieldsValue({ ...defaultFilterValues })
    }
  }, [defaultFilterValues])

  return (
    <>
      <div
        style={{
          display: "none",
          marginBottom: "10px",
          ...(isMobile ? { display: "block" } : {}),
        }}
      >
        <Button type="primary" onClick={handleClickShowFilters} style={{ width: "100%" }}>
          {showFilters ? "Ẩn bộ lọc" : "Hiển thị bộ lọc"}
        </Button>
      </div>
      <div
        style={{
          display: showFilters ? "block" : isMobile ? "none" : "block",
          width: "100%",
        }}
      >
        <Form
          name="customized_form_controls"
          layout="horizontal"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
          form={form}
        >
          <Space size={isMobile ? 4 : 10} wrap styles={{ item: { width: isMobile ? "100%" : "auto" } }}>
            {filters.map(filter => (
              <div key={filter.name} style={{ width: "100%" }}>
                <Form.Item
                  style={{ marginBottom: "0", width: "100%" }}
                  name={filter.name}
                  label={filter.label}
                  initialValue={getFilterInitialValue(filter.type, filter.defaultValue)}
                >
                  {renderFilterComponent(filter)}
                </Form.Item>
              </div>
            ))}
          </Space>
          <Space
            style={{
              marginLeft: "auto",
            }}
          >
            <Button onClick={handleClearFilters}>Xoá tìm kiếm</Button>
            {!isInstantSearch && (
              <Button type="primary" onClick={handleSubmitFilters}>
                Tìm kiếm
              </Button>
            )}
          </Space>
        </Form>
      </div>
    </>
  )
}

function getFilterInitialValue(
  type:
    | "text"
    | "select"
    | "date"
    | "number"
    | "range"
    | "checkbox"
    | "radio"
    | "switch"
    | "select-multiple"
    | "live-search"
    | "range_picker"
    | "debounce-search"
    | "select-search",
  value: any,
) {
  if (type === "date") {
    return value ? moment(value, "YYYY-MM-DD") : null
  }

  return value
}

export default Filters
