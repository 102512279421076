import { InventoriesRouter } from "@/modules/warehouses/inventories"
import { InventoryCategoriesRouter } from "@/modules/warehouses/inventory-categories"
import { InventoryTransactionsRouter } from "@/modules/warehouses/inventory-transactions"
import { Route, Routes } from "react-router-dom"

export const WareHousesRouter = () => {
  return (
    <Routes>
      <Route path="inventories/*" element={<InventoriesRouter />} />
      <Route path="inventory-transactions/*" element={<InventoryTransactionsRouter />} />
      <Route path="inventory-categories/*" element={<InventoryCategoriesRouter />} />
    </Routes>
  )
}
