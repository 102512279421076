
import { ACCESS_TOKEN } from "@/constants"
import { createInventoryCategory, fetchInventoryCategory, updateInventoryCategory } from "@/modules/warehouses/inventory-categories/services/inventoryCategories.services"
import { InventoryCategoryType } from "@/modules/warehouses/inventory-categories/types"
import { normalizeText, notificationAction, parseJwt } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import useLocalStorageState from "ahooks/lib/useLocalStorageState"
import { Button, Form, Input, Modal, Space } from "antd"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [formInvetoryCategory] = Form.useForm()
  const [inventoryCategoryData, setInventoryCategoryData] = useState<InventoryCategoryType>()
  const [isOpenModelAdd, setIsOpenModelAdd] = useState<boolean>(false)
  const [dataToken, setDataToken] = useState<{ fullName: string; id: string }>({ fullName: "", id: "" })
  const [accessToken, setAccessToken] = useLocalStorageState<string | null>(ACCESS_TOKEN)
  useEffect(() => {
    decodeToken();
  }, [accessToken])
  const decodeToken = () => {
    const decodedToken = accessToken && parseJwt(accessToken)
    if (decodedToken) {
      setDataToken({
        fullName: decodedToken.name || "",
        id: decodedToken.sub || "",
      })
    }
  }



  const onReset = () => {
    formInvetoryCategory.resetFields()
  }

  const handleDismissModal = () => {
    if (isOpenModelAdd) {
      setIsOpenModelAdd(false)
    } else {
      props.cancelProps()
      onReset()
    }
  }

  const handleCompleteForm = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      onReset()
      props.refreshProps()
      handleDismissModal()
    }, 1000)
  }

  const handleAdd = async () => {
    try {
      if (isOpenModelAdd === false) {
        const values = await formInvetoryCategory.validateFields()
        if (values.name) {
          const userId = dataToken.id;
          const newData: InventoryCategoryType = {
            status: StatusEnum.ACTIVE,
            created_by_id: userId,
            ...values,
          }
          const response = await createInventoryCategory(newData)
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
          }
        } else notificationAction("Vui lòng nhập thông tin đúng vào form! ", NotificationEnum.ERROR)
      }
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      formInvetoryCategory.resetFields()
    }

    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchInventoryCategory(props.idEdit).then(res => {
        formInvetoryCategory.setFieldsValue({
          name: res.data.name,
          quantity: res.data.quantity,
          unit_id: res.data.unit_id,
        })
        setInventoryCategoryData(res.data)
      })
    }
  }, [props.idEdit, props.actionType])

  const handleEdit = async () => {
    try {
      if (props.idEdit) {
        const values = await formInvetoryCategory.validateFields()
        if (values.name) {
          const response = await updateInventoryCategory(props.idEdit, {
            ...inventoryCategoryData,
            ...values,
          })
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
          }
        }
      }
      notificationAction("Vui lòng nhập đúng thông tin! ", NotificationEnum.ERROR)
    } catch (error) {
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button key="cancel" onClick={handleDismissModal}>
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleEdit} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAdd()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

  return (

    <Modal
      title={props.actionType === "CREATE" ? "Thêm danh mục" : "Quản lí danh mục"}
      open={!isOpenModelAdd && props.openProps}
      width={600}
      onCancel={props.cancelProps}
      footer={renderFooter()}
    >
      <Form
        name="wrap"
        labelCol={{ flex: "140px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ maxWidth: 600 }}
        form={formInvetoryCategory}
      >
        <Form.Item
          label="Tên danh mục:"
          name="name"
          rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
        >
          <Input placeholder="Nhập tên danh mục" />
        </Form.Item>

      </Form>
    </Modal>


  )
}
export default UpsertForm

const styles = {
  inputNumber: {
    width: "410px",
  },
}
