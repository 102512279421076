import Filters, { FilterComponent } from "@/components/Filters"
import { fetchPosition } from "@/modules/hrs/staffs/services/positions.services"
import { deleteStaff, fetchStaffs } from "@/modules/hrs/staffs/services/staff.services"
import { Gender, MAP_GENDER, MAP_POSITION, Position, PositionStaffType, Staff } from "@/modules/hrs/staffs/types"
import UpsertForm from "@/modules/hrs/staffs/views/UpsertForm"
import { ResponseData } from "@/types"
import { formatMoney, normalizeText } from "@/utils"
import { MAP_STATUS, StatusEnum } from "@/utils/constants"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Form, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<Staff>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [searchFilter, setSearchFilter] = useState<object>({})
  const [searchForm] = Form.useForm()
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPosition, setDataPosition] = useState<object[]>([])
  const [staffs, setStaffs] = useState<{ value: string | number; label: string }[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)

  const onRefresh = () => {
    setNeedRefresh(!needRefresh)
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }
  const onDismissModal = () => {
    setOpen(false)
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const getPositions = async () => {
    try {
      const response = await fetchPosition()
      if (response.statusCode === 200) {
        const filteredItems = response.data.map((item: PositionStaffType) => ({
          value: item.id,
          label: item.name,
        }))
        setDataPosition([...filteredItems])
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/hrs/staffs/${id}${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "4%",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
      width: "12%",
    },
    {
      title: "Ngày sinh",
      dataIndex: "birthday",
      key: "birthday",
      width: "8%",
      render: birthday => birthday && dayjs(new Date(birthday)).format("DD/MM/YYYY"),
    },
    {
      title: "SĐT",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "8%",
    },
    {
      title: "CCCD/CMND",
      dataIndex: "identification_id",
      key: "identification_id",
      width: "10%",
    },
    {
      title: "Quê quán",
      dataIndex: "hometown",
      key: "hometown",
      width: "10%",
    },
    {
      title: "Giới tính",
      dataIndex: "sex",
      width: "5%",
      key: "sex",
      render: (item: Gender) => {
        return <>{MAP_GENDER[item]}</>
      },
    },
    {
      title: "Chức vụ",
      dataIndex: "position_id",
      key: "position_id",
      width: "7%",
      render: (item: Position) => {
        return <>{MAP_POSITION[item]}</>
      },
    },
    {
      title: "Lương cơ bản",
      dataIndex: "basic_income",
      key: "basic_income",
      width: "10%",
      render: basic_income => `${formatMoney(basic_income)} đ`,
      sorter: true,
      sortDirections: ["descend", "ascend", null],
      sortOrder: sortPaginationValues
        ? sortPaginationValues.basic_income
          ? sortPaginationValues.basic_income === "asc"
            ? "ascend"
            : "descend"
          : null
        : null,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "9%",
      render: (status: StatusEnum) => {
        return <Tag color={status === StatusEnum.ACTIVE ? "success" : "error"}>{MAP_STATUS[status]}</Tag>
      },
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Space>
            <Button ghost type="primary" shape="round" size="small" onClick={() => showModalEdit(record?.id)}>
              Sửa
            </Button>
            <Popconfirm title="Bạn có chắc chắn muốn xóa?" onConfirm={() => handleDelete(record as Staff)}>
              <Button type="default" shape="round" danger size="small">
                Xóa
              </Button>
            </Popconfirm>
          </Space>
        ) : null,
      width: "15%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Staff) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchStaffs(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  useEffect(() => {
    getPositions()

    fetchStaffs({ limit: 1000 }).then((res: any) => {
      if (res.data.items && res.data.items.length) {
        const mappedStaffs = res.data.items.map((staff: Staff) => ({ value: staff.id, label: staff.name }))
        setStaffs(mappedStaffs)
      }
    })

    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  const handleDelete = async (record: Staff) => {
    await deleteStaff(record)
    setNeedRefresh(!needRefresh)
  }

  return (
    <div>
      <Card size="small">
        <Flex justify="space-between" style={{ flexWrap: "wrap" }}>
          <Typography.Title level={4} style={styles.title}>
            Danh sách nhân viên
          </Typography.Title>
          <Space style={{ flexWrap: "wrap", marginTop: "10px" }}>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Space>
        </Flex>
        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Tên nhân viên",
                  placeholder: "Chọn nhân viên",
                  name: "id",
                  type: "select-search",
                  options: staffs,
                },
                {
                  label: "Chức vụ",
                  placeholder: "Vui lòng chọn chức vụ",
                  name: "position_id",
                  type: "select-search",
                  options: dataPosition,
                  defaultValue: "",
                },
                {
                  label: "Giới tính",
                  placeholder: "Vui lòng chọn giới tính",
                  name: "sex",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: Gender.MALE, label: MAP_GENDER[Gender.MALE] },
                    { value: Gender.FEMALE, label: MAP_GENDER[Gender.FEMALE] },
                    { value: Gender.OTHER, label: MAP_GENDER[Gender.OTHER] },
                  ],
                  defaultValue: "",
                },
                {
                  label: "Trạng thái",
                  placeholder: "Vui lòng chọn trạng thái",
                  name: "status",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: StatusEnum.ACTIVE, label: "Hoạt động" },
                    { value: StatusEnum.DISABLED, label: "Không hoạt động" },
                  ],
                  defaultValue: "",
                },
              ] as FilterComponent[]
            }
          />
        </Card>
        <UpsertForm
          openProps={open}
          cancelProps={onDismissModal}
          refreshProps={onRefresh}
          actionType={actionType}
          idEdit={idEdit}
        />

        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ x: 1200, y: 400 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
            responsive: true,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
